<template>
    <div class="contain">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
	name: 'Taxes'
}
</script>
